@media only screen and (max-width: 1023px) {
    /* Login  */
    .login-page {
        width: 100%;
    }

    .login-right {
        display: none;
    }

    .screen {
        width: 100% !important;
        float: left;
        position: relative;
        padding: 85px 20px 0 20px;
        max-height: 59vh;
        overflow: auto;
    }

    .login-left-container {
        margin: 10px !important;
    }

    .login-container {
        width: 90%;
        height: 55%;
        display: flex;
        align-items: center;
    }

    .login-left-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .login-input {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .signin-button {
        width: 100%;
    }

    .signin {
        width: 100%;
    }

    button.sc-bczRLJ.cHapXD {
        width: 100%;
    }

    button.signin-google-now {
        width: 100%;
    }

    .signin-google {
        width: 100% !important;
    }

    .sign-up {
        margin-top: 14px !important;
    }

    button.sc-bczRLJ.bdxwBt {
        width: 100% !important;
    }

    .welcome-heading {
        width: 100%;
        display: block;
        align-items: flex-start;
        justify-content: flex-start;
    }

    /* Side-Navigation*/
    .main-navigation {
        width: 100%;
        background: #ffffff;
        padding-top: 75px;
        margin: 0;
    }

    .main-navigation a {
        text-decoration: none;
    }

    /* .main-navigation li {
        margin-top: 4px;
    } */

    .main-navigation li a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;
        width: 100%;
        height: 40px;
        padding: 20px 28px;
    }

    .main-navigation li a:hover {
        background: #f9fafb;
    }

    .main-navigation li a:hover {
        background-color: #f9fafb;
    }

    .list-drop-down {
        margin: 0px;
        padding: 0px;
    }

    .list-drop-down li {
        list-style: none;
    }

    .list-drop-down li a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 238px;
        height: 40px;
        padding-left: 32px;
        margin-top: 4px;
        color: #344054;
        border-radius: 6px;
        background: #ffffff;
    }

    .list-drop-down li span {
        padding-left: 16px;
    }

    .navbar-with-drop {
        padding: 24px 0px;
    }

    .nav-item {
        list-style: none;
        margin-bottom: 10px;
    }

    /* a.nav-item1 {
        margin-top: 0px !important;
    } */

    .navbar-expandedview {
        width: 98%;
        float: left;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        max-height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .mobile-menu {
        position: fixed;
        background-color: #667085;
        color: rgb(10, 10, 10);
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: all 0.4s;
        z-index: 999;
        height: 100vh;
    }

    .mobile-menu.open {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        transform: translateX(0%);
        width: 100%;
    }

    .closemenu {
        cursor: pointer;
        padding-top: 10px;
    }

    .sidebar-mobile {
        width: 100%;
    }

    .sidebar {
        width: 280px;
        height: 100%;
        float: left;
        background: #fff;
        border: 1px solid #eaecf0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        overflow: hidden;
        position: fixed;
    }

    .sidebar-active-icon {
        width: 100%;
    }

    .main-navigation li .sidebar-active-icon a {
        padding: 0 28px;
    }

    .main-navigation li a:hover {
        background-color: #ffffff;
    }

    /* Side-Navigation*/
    /* Searchbar*/
    /* .search-bar {
    width: 290px !important;
    height: 44px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-size: 16px;
    padding-left: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
}

.search-bar input {
    border: none;
    outline: none;
    padding: 7px;
}

.search-bar:hover {
    border: 1px solid #d6bbfb;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
}

.search {
    width: 20px;
    height: 20px;
} */
    /* Sidebar */

    .sidebar {
        display: none;
    }

    .sidebar-navigation-mobile {
        width: 94.8%;
        float: left;
        margin-bottom: 20px;
        position: fixed;
        z-index: 99;
        top: 0;
    }

    .sidebar-navigation-mobile-fixed {
        width: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
    }

    .phd-logo-mobile {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        padding: 10px 24px 10px 24px;
        background-color: #ffffff;
        box-shadow: 0px 4px 6px rgba(16, 24, 40, 0.05), 0px 0px 0px 0px #f9f8fb;
    }

    .phd-logo {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px !important;
    }

    .hamburger-icon {
        visibility: visible;
        opacity: 10;
        cursor: pointer;
        width: 22px;
    }

    .search-bar {
        width: 140px;
        height: 44px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        font-size: 16px;
        padding-left: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        visibility: visible;
        opacity: 10;
    }

    .search-bar input {
        border: none;
        outline: none;
        padding: 7px;
    }

    .search {
        width: 20px;
        height: 20px;
    }

    .search-bar:hover {
        border: 1px solid #d6bbfb;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
    }

    /* Header/Heading */
    .dashboard-heading {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        /* margin-bottom: 20px; */
        margin-top: 0px !important;
    }

    /* Footer */

    .footer-content {
        padding: 18px 0px 30px 0px;
    }

    .footer-navigation {
        margin: 0px;
        padding: 0px 28px 0px 28px;
        list-style: none;
        margin-top: 100px;
    }

    .footer-navigation li a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 24px 12px 24px 0px;
    }

    .footer {
        display: flex;
        width: 94% !important;
        padding: 0px !important;
    }

    .footer span {
        font-size: 12px !important;
    }

    .bottom-line-footer {
        width: 100%;
        height: 1px;
        background: #eaecf0;
    }

    .logout-icon {
        cursor: pointer;
    }

    /* Header */
    .breadcrumbs {
        visibility: hidden;
    }

    .header {
        display: none;
    }

    /* Table */
    .table-dash {
        overflow: scroll;
    }

    /* DefaultPageDRC*/
    .default-page {
        margin-top: 20px;
    }

    .default-content-scholar {
        margin-bottom: -30px;
        width: 100%;
    }

    /* AddCampusTabs*/
    .add-campus-tabs {
        cursor: pointer !important;
    }

    /* AddCampusPage-Form*/
    .university-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 24px;
    }

    .university {
        width: 100%;
        margin-top: 10px;
    }

    .university-name-input {
        width: 100%;
    }

    .heading-buttons {
        margin-top: 20px;
        margin-bottom: 0px !important;
    }

    /* AddMilestone*/
    .invite-supervisor-input {
        align-items: flex-start;
        flex-direction: column;
        margin-top: 0px;
    }

    .supervisor-name {
        margin-right: 0px;
        width: 100% !important;
        margin-top: 20px;
    }

    /* AddDeliverable*/
    .invite-supervisor-content {
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }

    .deliverable-name {
        width: 100% !important;
        margin-top: 18px;
    }

    /* ScholarsListPage*/
    .aasign-supervisor-select {
        margin-top: 0px;
        margin-right: 0px;
    }

    /* DeliverablePage*/
    .invite-deliverable-input {
        margin-top: 12px;
    }

    /* DefaultPages*/
    .default-link-buttons {
        margin-right: 0px;
    }

    /* HeadingLinkButtons*/
    .heading-link {
        background-color: #7f56d9;
        margin-right: 14px;
        font-weight: 300;
    }

    .plus-icon {
        padding-right: 12px;
    }

    .import-icon {
        display: none;
    }

    .heading-buttons a {
        color: #ffffff !important;
        width: auto;
    }

    .heading-link.plus-icon {
        padding-right: 12px;
    }

    .heading-buttons {
        flex-flow: wrap;
        align-items: flex-start;
        justify-content: flex-start !important;
    }

    .export-button {
        flex-flow: wrap;
    }

    .hAJsJC:hover {
        background-color: none !important;
    }

    .nUWBH:hover {
        background-color: none !important;
    }

    /* InviteSupervisor*/
    .supervisor-switch-content {
        display: flex;
        flex-direction: column;
    }

    /* PromoteScholars*/
    .promote-scholar-select {
        margin-top: 20px;
    }

    /* AllForms*/
    .bottom-line {
        width: 100% !important;
        height: 1px;
        background: #eaecf0;
        /* margin-top: 20px; */
    }

    /* Heading*/
    /* .heading-button-mobile .heading-buttons {
        margin: 0 !important;
    } */

    /* Form*/
    .span-text {
        display: none;
    }

    .text-area {
        width: 100%;
    }

    /* Card*/
    .card {
        width: 100%;
        background: #ffffff;
        border: 1px solid #eaecf0;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
            0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .card-info {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 16px;
    }

    .card-info-content {
        display: flex;
        align-items: flex-start !important;
        justify-content: space-between !important;
        padding: 10px 0px;
    }

    .card-info-content span {
        font-size: 14px;
        font-weight: 300;
        color: #344054;
        display: flex;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        width: 60%;
    }

    .card-info-content p {
        font-size: 14px;
        font-weight: 400;
        color: #344054;
        margin-bottom: 0px !important;
        text-align: right !important;
    }

    .card-info-content a {
        text-align: right !important;
    }

    .action-bottom {
        padding: 0px;
    }

    .bottom-line-card {
        width: 100% !important;
        height: 1px;
    }

    .card-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px 0px 16px 0px;
    }

    .card-actions a {
        padding-right: 20px;
        font-weight: 500 !important;
    }

    .card-action-edit a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
        font-size: 44px;
        text-decoration: none;
        color: #7f56d9;
        cursor: pointer;
    }

    .card-action-edit a:focus {
        color: #5d43a5;
    }

    /* UserProfileModal */
    .userprofileModal.modal.show .modal-dialog {
        max-width: 400px;
    }

    .personal-info-data {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 20px;
        flex-direction: column;
    }

    .personal-name {
        width: 100% !important;
        margin-right: 0px !important;
    }

    /* UserProfileModal */
    /* AddPatentModal */
    .patent-issue-input {
        margin-top: 18px;
    }

    /* UserProfileModal-CbButtons */
    .cb-buttons-userprofile {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 30px;
    }

    .userprofile-textarea {
        width: 100%;
    }

    /* FeePolicy */
    /* AssignedFeePolicy */
    .assigned-amount {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .amount-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100% !important;
    }

    .fee-policy-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }

    /* FeePolicy */
    /* AssignFeePolicy-Modal */

    .assign-fee-policy-amount {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 0px !important;
    }

    .milestone-fees {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 18px !important;
    }

    .bottom-line-assign-fee-policy {
        width: 300px;
        height: 1px;
        background: #eaecf0;
        margin-top: 10px;
    }

    /* UserProfile-PersonalInfo*/
    .progress-bar {
        display: none !important;
    }

    .profile-screen {
        width: 100%;
        float: right;
        position: relative;
        padding: 0px;
    }

    .personal-name {
        margin-top: 20px !important;
    }

    .dashboard-heading h1 {
        margin-top: 0px !important;
    }

    .personal-info {
        margin-top: 0px !important;
    }

    .personal-info-data {
        margin-top: 0px !important;
    }

    .user-profile-section {
        width: 100%;
        margin-top: -23px;
    }

    .personal-info-upload {
        flex-direction: column;
        align-items: center;
    }

    .personal-info-profile {
        width: 100%;
        margin-top: 30px;
    }

    .bottom-line-personal-info {
        width: 300px;
    }

    .user-personal-info-data {
        margin-top: 20px;
    }

    /* UserProfile-SocialSites*/
    .cKsmiX {
        width: 216px !important;
    }

    .MhKFg {
        width: 247px !important;
    }

    /* FeePolicy-Discount*/

    .aasign-supervisor-select {
        width: 318px !important;
    }

    .fee-discount-headings {
        margin-bottom: 10px !important;
    }

    .fee-discount-inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-top: 18px;
        flex-direction: column;
    }

    .fixed-milestone-input {
        display: none;
    }

    .fee-discount-inputs {
        display: none;
    }

    .discount-footer-content {
        display: none;
    }

    .fee-discount-content {
        width: 100%;
        flex-direction: column;
    }

    .fixed-amount-input-content {
        width: 100%;
    }

    .sc-bjUoiL.djNyON {
        width: 82%;
    }

    .discount-type-input-content {
        width: 100%;
        margin-top: 24px;
    }

    .discount-input-content {
        width: 100%;
        margin-top: 24px;
    }

    .after-discount-input-content {
        width: 100%;
        margin-top: 24px;
    }

    .discount-footer-content {
        display: none;
    }

    .discount-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .discount-footer-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .bottom-line-discount {
        display: none;
    }

    .bottom-line-discount-mobile {
        width: 320px;
        height: 1px;
        background: #eaecf0;
        margin-top: 20px;
    }

    /* CardActions */

    a {
        color: #7f56d9 !important;
        text-decoration: none !important;
    }

    .card-action-delete {
        list-style: none;
        font-size: 14px;
        text-decoration: none;
        color: #f04438 !important;
        cursor: pointer;
    }

    /* HeaderSelectFilter */

    .select-drop-down {
        width: 100% !important;
    }

    .filter-select-drop-down {
        display: flex;
        flex-direction: column !important;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    /* .sc-jSMfEi.bomFbP {
        margin-bottom: 20px !important;
    } */

    /* .sc-jSMfEi.cbLTNH {
        margin-bottom: 20px !important;
    }  */

    .filter-input {
        margin-right: 0px !important;
    }

    .header-filter-input {
        margin-bottom: 20px;
        margin-right: 0px !important;
        margin-top: 0px !important;
    }

    .header-filter {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    /*View/Add/EditPages - BackButtons*/

    .nUWBH {
        justify-content: flex-start !important;
    }

    .hAJsJC {
        justify-content: flex-start !important;
        border-radius: none !important;
    }

    a.sc-eCYdqJ.hAJsJC {
        align-items: center;
        justify-content: flex-start !important;
    }

    .sc-eCYdqJ.iPeqyb {
        padding: 0px 0px !important;
    }

    .eQcvkG:focus {
        box-shadow: none !important;
    }

    .bwchtr:focus {
        box-shadow: none !important;
    }

    .view-general-content {
        display: flex;
        flex-direction: column;
    }

    .general-leftcontent p {
        width: 100% !important;
    }

    .back-button a {
        justify-content: flex-start !important;
    }

    /*AddPages*/

    .fiPtPH {
        width: 96% !important;
    }

    .dXNwvb {
        width: 100% !important;
    }

    /* Pagination */

    .pagination-content {
        width: 100% !important;
        display: flex;
        flex-direction: column;
    }

    .pagination-right {
        width: 100% !important;
        margin-top: 20px;
    }

    .pagination {
        width: 100% !important;
    }

    .select-items-pagination {
        width: 100% !important;
    }

    .select-items-pagination select {
        padding-left: 170px;
    }

    nav {
        width: 100% !important;
    }

    li.page-item.border-radius-left.disable {
        width: 50%;
    }

    li.page-item.border-radius-right.disable {
        width: 50%;
    }

    li.page-item.border-radius-left {
        width: 50%;
    }

    li.page-item.border-radius-right {
        width: 50%;
    }

    .arrows-page {
        display: flex;
        padding-top: 2px;
    }

    .arrows-page-next {
        display: flex;
        padding-top: 2px;
        justify-content: flex-end;
    }

    .arrows-page span {
        align-items: flex-end;
        justify-content: flex-end;
        display: flex;
        /* width: 54%; */
    }

    .arrows-page span1 {
        align-items: flex-end;
        justify-content: flex-end;
        display: flex;
        /* width: 70%; */
    }

    td {
        padding: 0px !important;
    }

    /* Form-Input */

    .fTckCf {
        width: 96% !important;
    }

    /* Form-Textarea */

    .fRgToT {
        width: 96% !important;
    }

    /* MilestonePage */

    .aasign-supervisor-select {
        width: 100% !important;
    }

    /* PromoteScholarPage */

    .promote-supervisor-select {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .promote-scholar-select {
        width: 100% !important;
    }

    .cb-buttons-scholars {
        margin-top: 20px !important;
        display: flex;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }

    .cb-buttons {
        margin-right: 0px;
    }

    /* AddInput-Page */

    .sc-gsnTZi.fTckCf {
        width: 100% !important;
    }

    .sc-iBkjds.fRgToT {
        width: 100% !important;
    }

    .card-right-info a {
        font-weight: 500 !important;
    }

    .card-right-info td a {
        text-align: right !important;
    }

    td {
        text-align: right;
    }

    /* AddSupervisorPage  */

    .invite-supervisor-inputs {
        margin-top: 0px !important;
        flex-direction: column;
    }

    /* AddPagesSimpleInput */

    .sc-gsnTZi.fiPtPH {
        width: 100% !important;
    }

    /* LoginFormInput */

    .sc-dkzDqf.gLnEbK {
        width: 100% !important;
    }

    .sc-dkzDqf.cxlnQI {
        width: 100% !important;
    }

    .login-input-field {
        width: 100% !important;
    }

    /* Card-NoRecordFound */

    .no-records-incard {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    /* AddFeePolicy */

    .add-fee-policy-field {
        flex-direction: column;
    }

    .choose-milestone-amount {
        width: 100%;
    }

    .sc-ksZaOG.coGEWo {
        width: 168px;
    }

    .enter-fee-amount {
        width: 46%;
    }

    .choose-milestone-amount {
        margin-top: 20px;
    }

    .collection-button {
        margin-right: 20px;
    }

    .eJwOae {
        padding: 0px !important;
    }

    /* TextEditor-Description */

    .rdw-editor-toolbar {
        width: 100%;
    }

    .rdw-editor-main {
        width: 100%;
    }

    /* SideMenu */

    .navbar-expandedview {
        width: 98%;
        float: left;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        max-height: 59vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .user-info {
        width: 100%;
    }

    /*DeleteModal*/

    .modal-dialog-centered {
        min-height: calc(100% - var(--bs-modal-margin) * 15) !important;
    }

    /* BackButton */

    .view-back-button {
        display: flex !important;
    }

    .back-button a:focus {
        box-shadow: none !important;
    }

    .heading-buttons a.view-back-button {
        padding: 0px !important;
        box-shadow: none !important;
        background-color: #ffffff !important;
    }

    .back-button a.view-back-button {
        padding: 0px !important;
    }

    /* UploadDragDrop */

    .file-upload {
        height: 18vh;
    }

    .uploaded-images-size {
        width: 100px;
        height: 100px;
    }

    /* FeeWaiver-Modal */

    .discountModal.modal.show .modal-dialog {
        max-width: 608px;
    }

    .personal-info-about {
        margin-top: 20px;
    }

     /* Pagination-ForTab */

    .select-items-pagination span {
        width: 90%;
    }

    .select-items-pagination select {
        padding-left: 16px;
    }
}

@media only screen and (max-width: 1536px) {

    .screen {
        min-height: 69vh;
    }

    .navbar-expandedview {
        min-height: 69vh;
    }
}

@media only screen and (max-width: 767px) {
    .screen {
        width: 100% !important;
        float: left;
        position: relative;
        padding: 85px 20px 0 20px;
        max-height: 100vh;
        overflow: auto;
    }

    .navbar-expandedview {
        width: 98%;
        float: left;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        max-height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .sidebar-navigation-mobile {
        width: 89%;
    }

    .cb-buttons {
        margin-bottom: 20px;
    }

    .login-container {
        width: 90%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .login-left-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .eJwOae {
        padding: 0px !important;
    }
    /*
    Form-AddPages */

    .university-input-heading {
        width: 100%;
    }

    /* TextEditor-Description */

    .rdw-editor-toolbar {
        width: 100%;
    }

    .rdw-editor-main {
        width: 100%;
    }

    /* DateField */

    input[type='date'] {
        position: relative;
        padding: 10px !important;
    }

    input[type='date']::before {
        top: 9px;
    }

    /* UploadDragDrop */

    .file-upload {
        padding: 20px !important;
    }

    /* HomePageLoader */

    .home-page-loader {
        right: 38%;
    }

    /* HeadingButton-Export */

    .export-button {
        margin-left: 0px;
    }

    .export-button-link {
        border: 1px solid #eaecf0;
        border-radius: 6px;
        width: 46px;
        height: 40px;
        background-color: #ffffff;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        flex-flow: wrap;
    }

    .export-button-link:hover {
        background-color: #f9fafb;
    }

    /* RemovedImport-Button */

    .import-scholar {
        display: none !important;
    }

    .import-staff {
        display: none !important;
    }

    .import-supervisor {
        display: none !important;
    }

    /* UploadImage-Profile */

    .dummy-no-profile-image {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
    }

    .uploaded-images-col {
        margin-bottom: 20px;
    }

    /* BackButton  */

    .back-button a:focus {
        box-shadow: none !important;
    }

    /* CollectionModal */

    .modal-box {
        align-items: flex-start;
    }

    /* ForgotPage */

    .forgot-back-to-login {
        width: 100% !important;
    }

    .scrollable-element {
        scrollbar-color: red yellow !important;
    }

    /* ScrollbarColorChange */

    ::-webkit-scrollbar {
        width: 0.1rem;
    }

    ::-webkit-scrollbar-track {
        background-color: #eaecf0;
    }

    /* FeeWaiver */

    .fee-waiver {
        display: flex;
        flex-direction: column;
    }

    .fee-waiver-content {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .fee-waiver-heading {
        font-weight: 500;
        font-size: 14px;
        color: #101828;
        margin-bottom: 10px;
    }

    .bottom-line-fee-waiver {
        width: 280px;
        height: 1px;
        background: #eaecf0;
        margin-top: 24px;
    }

    .fee-waiver-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .total-fee h4 {
        font-weight: 700;
        font-size: 20px;
        color: #101828;
    }

    .total-amount-fee h4 {
        font-weight: 700;
        font-size: 20px;
        color: #7f56d9;
    }

    .pagination {
        width: 100%;
    }
}
