body {
    background: #ffffff !important;
    font-family: 'Inter', sans-serif;
}

/* LoginPage */
/* LoginRight */

.login-right {
    width: 50%;
    min-height: 100vh;
    background: linear-gradient(45deg, #101828 0%, #475467 100%);
    color: rgb(241, 241, 241);
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-page form {
    width: 100%;
    height: 100%;
}

.login-right-content {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
}

.star-image {
    width: 100%;
    margin-bottom: 33px;
}

.login-right-heading h1 {
    color: #fff;
    font-size: 44px;
    line-height: 54px;
    margin-bottom: 30px;
}

.login-heading span {
    width: 80%;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
}

.avatar-group {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.avatar-group span {
    padding-left: 16px;
}

/* LoginPage */
/* LoginLeft */

.login-page {
    width: 50%;
    height: 100vh;
    background-color: #fff;
    color: rgb(241, 241, 241);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 1px #d3d3d3;
    float: left;
}

.cHapXD {
    transition: none !important;
}

.bdxwBt {
    transition: none !important;
}

/* PHD Logo */

.phd-logo-login {
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    height: 70px;
}

.welcome-heading {
    width: 360px;
    display: block;
    align-items: center;
    justify-content: center;
}

.welcome-heading h1 {
    margin-top: 48px;
    font-weight: 600;
    font-size: 36px;
    color: #101828;
}

.login-left-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex: 1 1;
    flex-direction: column;
}

.login-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

label {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    padding-bottom: 4px;
}

.login-right-heading {
    width: 100%;
}

.login-input {
    width: 360px;
    display: block;
    align-items: center;
    margin-top: 32px;
}

.login-input {
    margin-top: 20px;
}

.login-input span {
    font-size: 16px;
    padding-left: 0px;
    color: #c04343;
}

.llabel-input {
    color: #344054;
}

.signin-button {
    margin-top: 20px;
}

.signin-google {
    margin-top: 20px;
}

.signin-google-now {
    width: 360px;
    height: 44px;
    color: #fff;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    color: #344054;
}

.signin-google-now:hover {
    background-color: #f9fafb;
    color: #344054;
}

.google-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

/* Don't have an account */

.forgot-reset-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
}

.forgot-reset-link a {
    color: #7f56d9;
    text-decoration: none;
    padding-left: 4px;
}

.forgot-reset-link a:hover {
    color: #5d43a5 !important;
}

.back-to-login {
    margin-top: 8px;
}

.back-to-login a {
    color: #7f56d9;
    text-decoration: none;
}

.back-to-login a:hover {
    color: #5d43a5;
}

.forgot-back-to-login {
    margin-top: 50px;
}

/* Footer*/

.footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 20px 20px;
}

.footer span {
    color: #667085;
    font-size: 14px;
    font-weight: 400;
}

.footer a {
    color: #7f56d9;
    text-decoration: none;
}

.footer a:hover {
    color: #5d43a5;
}

.logout-icon {
    cursor: pointer;
}

.email {
    padding-right: 7px;
}

.sl-spinner3 .sl-loader .sl-loader-parent {
    height: auto !important;
}

/* ForgotPasswordScreen */

.forgot-password-description span {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: #667085;
    margin-top: 20px;
}

/* Footer-Settings */

.settings-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
}

/* Sidebar*/

.sidebar {
    width: 280px;
    height: 100%;
    float: left;
    background: #fff;
    border: 1px solid #eaecf0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    overflow: auto;
    position: fixed;
}

.sidebar-navigation {
    width: 100%;
    float: left;
}

.phdpad-logo {
    width: 138px !important;
}

.phd-logo {
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding: 16px 0px 16px;
    width: 100%;
}

.phd-logo span {
    padding-left: 7px;
    font-size: 19px;
    font-weight: 700;
}

.hamburger-icon {
    visibility: hidden;
    opacity: 0;
}

/* .search-bar {
    width: 234px;
    height: 44px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-size: 16px;
    padding-left: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-bar input {
    border: none;
    outline: none;
    padding: 7px;
}

.search {
    width: 20px;
    height: 20px;
}

.search-bar:hover {
    border: 1px solid #d6bbfb;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
} */

.navbar-with-drop {
    padding: 8px 0px;
}

.navbar-with-drop ul li {
    display: flex;
    flex-direction: column;
    list-style: none;
}

.navbar-with-drop .nav-item {
    margin-bottom: 8px;
}

.navbar-with-drop ul li a {
    text-decoration: none;
}

.home-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-navigation {
    padding: 0px;
    margin: 0;
}

.main-navigation li .sidebar-active-icon a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    border-radius: 0px;
    background: #ffffff;
    width: 100%;
    height: 40px;
}

.main-navigation li.active .list-drop-down li.active .sidebar-active-icon a {
    border-right: none;
    background-color: #000;
}

.main-navigation li.active .list-drop-down .sidebar-active-icon a {
    border-right: none;
    padding-left: 44px;
}

.main-navigation li a:hover {
    background: #F9FAFB;
}

.main-navigation li a:hover {
    background-color: #F9FAFB;
}

.list-drop-down {
    margin: 0px;
    padding: 0px;
}

.list-drop-down li {
    padding: 0px;
}

li.active a img.sidebar-icons.inactive-icons {
    display: none;
}

.nav-item.active a img.sidebar-icons.active-icons {
    display: flex;
}

/* Sidebar-ListDropdown-Active/Inactive */

.nav-item a img.drop-down.active-icon {
    display: none;
}

.nav-item a img.drop-down.inactive-icon {
    display: flex;
}

li.active a img.drop-down.inactive-icon {
    display: none;
}

.nav-item.active a img.drop-down.active-icon {
    display: flex;
}

/* ___________________________ */

.nav-item.active .nav-content span {
    color: #7f56d9;
}

.nav-item1.active .nav-content span {
    color: #7f56d9;
}

.nav-item.active .sidebar-active-icon a {
    border-right: 2px solid #7f56d9;
}

.nav-item.active .list-drop-down span {
    color: #344054;
}

.nav-item a img.sidebar-icons.active-icons {
    display: none;
}

li.active a {
    background: #ffffff;
}

.list-drop-down li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 238px;
    height: 40px;
    padding-left: 32px;
    margin-top: 4px;
    color: #344054;
    border-radius: 6px;
    background: #ffffff;
}

.list-drop-down li a:hover {
    color: #000000;
}

.nav-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-content span {
    font-size: 16px;
    font-weight: 400;
    color: #344054;
    padding-left: 12px;
}

.nav-content-campus-setup {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-content-campus-setup span {
    font-size: 16px;
    font-weight: 400;
    color: #344054;
    padding-left: 8px;
}

.sidebar-icons {
    width: 20px;
    height: 20px;
}

.sidebar-infrastructure-icons {
    width: 20px;
    height: 20px;
}

.sidebar-campus-icon {
    width: 24px;
    height: 24px;
}

.drop-down {
    margin-right: 2px;
}

/* Sidebar-DropdownActiveTab */

.dropdown-active-tab {
    color: #000000 !important;
}

.sidebar-active-icon a.nav-item1.active {
    background-color: #F9FAFB !important;
}

/* Sidebar */
/* SidebarFooter */

.sidebar-footer {
    padding: 24px 0px 0 0;
    width: 100%;
}

.sidebar-footer ul li {
    display: flex;
    flex-direction: column;
    list-style: none;
}

.sidebar-footer ul li a {
    text-decoration: none;
}

.footer-navigation {
    padding: 0px 24px;
    margin: 0;
}

.footer-navigation li a {
    margin: 12px 12px 12px 0px;
}

.footer-navigation li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.footer-content {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 92%;
}

/* .user-info p {
    align-items: center;
    justify-content: space-between;
    width: 92%;
} */

.support {
    display: flex;
    align-items: center;
    justify-content: center;
}

.support span {
    font-size: 16px;
    font-weight: 400;
    color: #344054;
    padding-left: 12px;
}

.settings {
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings span {
    font-size: 16px;
    font-weight: 400;
    color: #344054;
    padding-left: 12px;
}

.settings span:hover {
    color: #5d43a5;
}

.footer-icon {
    width: 20px;
    height: 20px;
}

.footer-icon:hover {
    color: #5d43a5;
}

.footer-avatar {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    padding-left: 10px;
    cursor: default;
}

.footer-avatar span {
    font-size: 16px;
    font-weight: 400;
    color: #344054;
}

.footer-avatar p {
    font-size: 14px;
    font-weight: 400;
    color: #667085;
    margin: 0;
}

.logout {
    padding-left: 40px;
}

/* Header */

.screen {
    width: calc(100% - 280px);
    float: right;
    position: relative;
    padding: 2px 32px;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.breadcrumbs {
    font-size: 14px;
    font-weight: 400;
    color: #667085;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.breadcrumbs:hover {
    color: #7f56d9;
}

.breadcrumb-lasttitle {
    color: #344054;
    background: #f9fafb;
    width: auto;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    padding: 12px;
}

.home-icon {
    width: 17px;
    height: 17px;
}

.arrow-icon {
    padding: 16px;
}

/* DashboardHeading*/

.dashboard-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 20px;
}

.dashboard-heading h1 {
    font-size: 24px;
    font-weight: 500;
    color: #101828;
    margin-bottom: 6px !important;
}

.heading-buttons {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: row !important;
    margin-bottom: 18px;
}

.heading-link {
    width: 'auto';
    height: 40px;
    border: 1px solid #eaecf0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-right: 10px;
    text-decoration: none;
    border-radius: 8px;
    font-size: 14px;
    color: #667085;
}

.heading-link:hover {
    background-color: #f9fafb;
    color: #667085;
}

.heading-link-back {
    width: 'auto';
    height: 44px;
    border: none;
    display: flex;
    align-items: center;
    margin-right: 14px;
    text-decoration: none;
    border-radius: 8px;
    font-size: 14px;
    color: #667085;
}

.export-button {
    border: 1px solid #eaecf0;;
    border-radius: 6px;
    width: 46px;
    height: 40px;
    background-color: #ffffff;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    margin-left: 10px;
}

.export-button:hover {
    background-color: #f9fafb;
}

.export-button-link {
    border: 1px solid #eaecf0;
    border-radius: 6px;
    width: 46px;
    height: 40px;
    background-color: #ffffff;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    margin-left: 20px;
    flex-flow: wrap;
}

.export-button-link:hover {
    background-color: #f9fafb;
}

.eJwOae {
    padding: 0px !important;
}

.heading-link-back:hover {
    background-color: none;
    color: #7f56d9;
    padding: 0px 20px;
}

.profile-heading .dashboard-heading h1 {
    font-size: 14px;
}

.profile-heading .dashboard-heading span {
    font-size: 14px;
}

.dashboard-heading span {
    font-size: 15px;
    font-weight: 400;
    color: #667085;
}

.export {
    margin-right: 6px;
}

.select-dropdown {
    flex-direction: column;
}

/* TableDashboard*/

.table-name-heading {
    font-size: 12px !important;
}

.table-dash {
    width: 100%;
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1),
    0px 1px 3px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    margin-top: 16px;
}

.table-content table thead th {
    padding: 20px 0px 20px 24px;
}

.table-content span {
    padding: 20px 0px;
    display: block;
}

.table-content table thead tr th {
    padding: 12px 0px 12px 24px;
}

.table-content tbody tr td {
    padding: 12px 0px 12px 24px;
}

.table-content table thead tr th {
    border-top: 1px solid #eaecf0;
    background-color: #f9fafb;
}

.table-content table {
    width: 100%;
}

.table-content thead th {
    font-size: 18px;
    font-weight: 400;
    color: #101828;
}

.table-content table tr {
    border-bottom: 1px solid #eaecf0;
}

.table-content table tr th {
    font-size: 12px;
    font-weight: 400;
    color: #667085;
}

.table-content th,
td {
    padding: 10px;
    text-align: left;
}

.table-content tbody td {
    font-size: 14px;
    color: #212529;
}

tr:hover {
    background-color: #f9fafb;
}

.delete-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
}

.delete-row-button {
    padding: 12px 0px;
}

.table-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.table-checkbox span {
    padding-left: 12px;
}

.checkbox {
    padding-right: 10px;
    cursor: pointer;
}

.downward-arrow {
    padding-left: 7px;
}

.clusters {
    display: flex;
    align-items: center;
}

.clusters a {
    padding: 0px 18px 0px 0px;
}

.clusters-link {
    list-style: none;
    font-size: 14px;
    text-decoration: none;
    color: #7f56d9;
    width: 56px;
}

.clusters-link:hover {
    color: #5d43a5;
}

.view-campus {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 14px;
    text-decoration: none;
    color: #667085;
}

.view-campus a {
    padding: 0px;
}

.view-campus:hover {
    color: #7f56d9;
}

.view {
    width: 24px;
    height: 24px;
}

.actions {
    display: flex;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 24px;
}

.actions .action-delete {
    color: #f04438;;
}

.actions .action-delete:hover {
    color: #B42318;
}

.actions a {
    padding: 0px 12px 0px 0px;
}

.action-edit {
    list-style: none;
    font-size: 14px;
    text-decoration: none;
    color: #7f56d9;
    cursor: pointer;
}

.action-edit:hover {
    color: #5d43a5;
}

.action-delete {
    list-style: none;
    font-size: 14px;
    text-decoration: none;
    color: #667085;
    cursor: pointer;
}

.action-delete a:hover {
    color: #f04438;
}

.action-assign-fee {
    list-style: none;
    font-size: 14px;
    text-decoration: none;
    color: #7f56d9;
    cursor: pointer;
}

.action-assign-deliverable {
    list-style: none;
    font-size: 14px;
    text-decoration: none;
    color: #7f56d9;
    cursor: pointer;
}

th.action-th {
    width: 150px !important;
}

.select-show-items {
    margin-top: 18px;
}

.table-content a {
    text-decoration: none;
    color: #7f56d9;
}

.table-content a:hover {
    text-decoration: none;
    color: #5d43a5;
}

/* AddCampus*/
/* Tabs*/

.add-campus-tabs {
    margin-top: 18px;
}

.add-campus-tabs ul {
    width: 100%;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    float: left;
    margin-bottom: 30px;
    display: flex;
}

.add-campus-tabs ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    border: 1px solid #d0d5dd;
    border-right: none;
    padding: 10px;
    position: unset;
}

.add-campus-tabs ul .react-tabs__tab--selected {
    background: #f9fafb;
}

li#tab\:r0\:0 {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1px solid #d0d5dd;
}

li#tab\:r0\:1 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

li#tab\:r0\:2 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

li#tab\:r0\:3 {
    border-right: 1px solid #d0d5dd;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.add-campus-tabs li:hover {
    background-color: #f9fafb;
}

/* AddCampus*/
/* General/Legal/Office/AboutTab*/

.tabs-border-bottom hr {
    color: #eaecf0;
}

.general-tab-heading {
    margin-top: 30px;
}

.general-tab-heading h4 {
    font-size: 18px;
    color: #101828;
    margin-bottom: 4px;
}

.university-input {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;
}

.university-input-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.general-tab-heading span {
    font-size: 14px;
    font-weight: 400;
    color: #667085;
}

.general-tab-heading hr {
    width: 1048px;
    color: #eaecf0;
}

.university-input-heading {
    width: 30%;
}

.university {
    width: 68%;
}

.university-input-heading h4 {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    margin-bottom: 0px !important;
}

.university-input-heading span {
    font-size: 14px;
    font-weight: 300;
    color: #667085;
}

/* .university {
    width: 64%;
} */

.university-name-input {
    width: 484px;
    align-items: center;
}

.university-name-input.fullWidth {
    width: 112%;
}

.cb-buttons-deliverable {
    display: flex;
    float: left;
    margin-top: 20px;
}

/* AboutTab*/
/* text-area */

.text-area textarea {
    width: 100% !important;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 10px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    color: #1d2939;
}

.text-area textarea::placeholder {
    color: #c1c1c1;
}

.text-area textarea:hover {
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 2px #f4ebff;
}

.eFeaNR:hover {
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 2px #fff0f0 !important;
}

/* Modals */

.userprofile-modal {
    margin: 26px;
}

.modal-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.userprofileModal.modal.show .modal-dialog {
    max-width: 800px;
}

.modal-content {
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
}

.modal-heading span {
    font-weight: 500;
    font-size: 16px !important;
    color: #101828;
}

/* .modal-heading span {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #667085;
} */

.modal-checkbox {
    display: flex;
    align-items: center;
    margin-top: 14px;
}

.modal-checkbox span {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #344054;
}

img.modal-cross-icon {
    cursor: pointer;
}


.modal-input {
    width: 722px;
    align-items: center;
    margin-top: 20px;
}

.modal-text-area-label {
    font-size: 14px;
    font-weight: 400;
    color: #344054;
    padding-bottom: 4px;
}

.text-area textarea {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 10px !important;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    color: #1d2939;
}

/* Modals */
/* DeleteModal */

.delete-modal {
    margin: 26px;
}

.delete-modal-img {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.deleteModal.modal.show .modal-dialog {
    max-width: 400px;
}

.modal-content {
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
}

.delete-modal-heading h5 {
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
    color: #101828;
}

.delete-modal-heading span {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #667085;
}

.modals-cb-buttons {
    display: flex;
    margin-top: 32px;
}

/* Modals */
/* ErrorModal */

.error-modals-cb-buttons {
    display: flex;
    margin-top: 32px;
}

.errorModal.modal.show .modal-dialog {
    max-width: 400px;
}

button.sc-bczRLJ.duwcJz {
    transition: none !important;
}

/* SelectInput*/

.select-drop-down {
    width: 250px !important;
}

.select-drop-down .css-1s2u09g-control {
    min-height: 44px;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
}

.select-drop-down .css-1pahdxg-control {
    min-height: 44px;
    border-radius: 8px;
    border-color: #7f56d9 !important;
    box-shadow: none !important;
}

/* .css-1pahdxg-control  {
    border-color:#F04438 !important;
} */

.css-1s2u09g-control {
    height: 44px;
    border-radius: 8px !important;
    border: 1px solid #D0D5DD !important;
}

.select-multi .css-1s2u09g-control {
    min-height: 44px;
    border-radius: 8px !important;
    border: 1px solid #D0D5DD !important;
    height: 100%;
}

.select-multi .css-1pahdxg-control {
    min-height: 44px;
    border-radius: 8px !important;
    border: 1px solid #D0D5DD !important;
    height: 100%;
}

.css-1hb7zxy-IndicatorsContainer {
    align-items: flex-start !important;
    justify-content: flex-start;
}

.css-1wy0on6 {
    align-items: flex-start !important;
}

.css-1s2u09g-control:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #fff0f0;
}

.css-1pahdxg-control {
    height: 44px;
    border-radius: 8px !important;
    border-color: #7f56d9 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff;
}

.css-1pahdxg-control:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff !important;
}

.css-1pahdxg-control-option:hover {
    background-color: #7f56d9 !important;
}

.css-1gtu0rj-indicatorContainer {
    color: hsl(0, 0%, 80%) !important;
}

.css-1gtu0rj-indicatorContainer .css-tj5bde-Svg path {
    color: hsl(0, 0%, 51%) !important;
}

path {
    color: hsl(0, 0%, 80%) !important;
}


span.css-1okebmr-indicatorSeparator {
    width: 0px !important;
}

.select-drop-down .css-1n7v3ny-option {
    background-color: #f1ecfe !important;
}

.css-9gakcf-option {
    background-color: #ffffff !important;
    color: #101828 !important;
}

.select-drop-down span.css-1okebmr-indicatorSeparator {
    width: 0px;
}

.select-drop-down .css-1s2u09g-control:hover {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff;
}

.css-1gtu0rj-indicatorContainer path {
    color: #667085 !important;
}

/* ViewCampus*/
/* ViewCampus-GeneralInformation*/

.view-campusdetails hr {
    color: #cccccc;
}

.view-general {
    margin-top: 30px;
}

.view-generalheading h4 {
    font-size: 18px;
    font-weight: 400;
    color: #101828;
}

.view-general-content {
    display: flex;
    align-items: flex-start !important;
    justify-content: space-between !important;
    margin-bottom: 28px;
    width: 90%;
}

.general-leftcontent span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #667085;
    font-size: 14px;
    font-weight: 400 !important;
    vertical-align: text-top !important;
}

.general-leftcontent p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #344054 !important;
    font-size: 16px;
    font-weight: 400 !important;
    margin-bottom: 0px !important;
    width: 56% !important;
}

/* ViewCampus*/
/* ViewCampus-LegalInformation*/

.legal-rightcontent {
    margin-right: 370px;
}

.legal-rightcontent span {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: #344054;
    font-size: 16px;
    font-weight: 400;
}

.legal-rightcontent a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: #7f56d9;
    font-size: 16px;
    font-weight: 400;
}

.legal-rightcontent a:hover {
    color: #5d43a5;
}

/* ViewCampus*/
/* ViewCampus-OfficeInformation*/

.office-rightcontent {
    margin-right: 316px;
}

.office-rightcontent span {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: #344054;
    font-size: 16px;
    font-weight: 400;
}

/* ViewCampus*/
/* ViewCampus-AboutInformation*/

.about-leftcontent span {
    display: flex;
    flex-direction: column;
    color: #667085;
    font-size: 14px;
    font-weight: 400;
    margin-top: 46px;
}

.about-leftcontent span:first-child {
    margin-top: 22px;
}

.about-leftcontent span:nth-child(3) {
    margin-top: 84px;
}

.about-leftcontent span:nth-child(4) {
    margin-top: 18px;
}

.view-about {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -15px;
}

.about-rightcontent {
    width: 40%;
    margin-right: 76px;
}

.about-rightcontent span {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: #344054;
    font-size: 16px;
    font-weight: 400;
}

/* AddAcademicYears */

.switch {
    cursor: pointer;
}

.switch.readonly {
    cursor: pointer !important;
}

.switch-text {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.switch-text span {
    font-weight: 400;
    font-size: 14px;
    color: #344054;
    padding-left: 8px;
}

/* User Profile */
/* ProgressBar */

.user-profile-section {
    width: 100%;
}

.progress-bar {
    width: 20%;
    float: left;
    flex-direction: column;
    margin-top: 18px;
}

.progress-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.success-line {
    width: 2px;
    height: 16px;
    background: #7f56d9;
}

.default-line {
    width: 2px;
    height: 16px;
    background: #eaecf0;
}

.progress-bar-content ul {
    padding-left: 0px !important;
}

.progress-bar-content ul li a {
    text-decoration: none;
}

.progress-bar-content ul li {
    display: flex;
    align-items: flex-start;
    list-style: none;
}

.personal-info-tab {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 6px;
}

.personal-info-tab span {
    color: #475467;
    padding-left: 10px;
    font-size: 14px;
}

.personal-info-tab span:hover {
    color: #7f56d9;
}

.personal-info-tab span:active {
    color: #7f56d9;
}

/* UploadDragnDrop */

.upload-drag-drop {
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 100%;
}

.file-upload {
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 18vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.file-upload:hover {
    border: 1px solid #d2c4f2;
    background-color: #fbfafd;
}

.upload {
    text-align: center;
    padding: 10px;
}

.upload-image {
    padding-bottom: 4px;
}

.drag-text {
    text-align: center;
    font-size: 14px;
    color: #7f56d9;
}

.drag-text:hover {
    color: #5d43a5;
}

.drag-text span {
    font-size: 14px;
    font-weight: 300;
    color: #667085;
}

/* Upload-file */

.upload-file {
    width: 100%;
    margin-top: 20px;
}

.CSVImporter_Importer {
    box-sizing: border-box !important;
    line-height: 1.4;
}

.CSVImporter_Importer span {
    font-size: 14px !important;
}

.CSVImporter_Importer span:hover {
    color: #5d43a5 !important;
}

.CSVImporter_FileSelector {
    border: 1px solid #eaecf0 !important;
    padding: 4em;
    border-radius: 8px !important;
    background: #ffffff !important;
    text-align: center;
    color: #7f56d9 !important;
    cursor: pointer;
}

.CSVImporter_FileSelector:hover {
    border: 1px solid #d2c4f2 !important;
    background: #fbfafd !important;
}

.upload-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #7f56d9;
    font-weight: 400;
    font-size: 14px;
    margin-top: 15px;
}

.upload-footer:hover {
    color: #5d43a5;
}

.upload-footer span:hover {
    cursor: pointer;
}

/* User Profile */
/* Personal Info*/

.profile-screen {
    width: 80%;
    float: right;
    position: relative;
    padding: 0px 32px;
}

.personal-info {
    width: 100%;
    height: auto;
    border: 1px solid #eaecf0;
    border-radius: 6px;
    margin-top: 20px;
    padding: 0px 24px 24px;
}

.personal-info-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.user-personal-info-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.personal-name {
    width: 48% !important;
    margin-right: 20px;
}

.user-personal-info-data {
    margin-top: 20px;
}

.personal-name-input {
    width: 97%;
}

.about-info {
    width: 97%;
    margin-top: 20px;
}

.personal-info-about {
    font-size: 14px;
    font-weight: 400;
    color: #344054;
    padding-bottom: 4px;
}

.personal-info-profile {
    width: 12%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
}

.personal-info-upload {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.personal-info-uploadfile {
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.cb-buttons-userprofile {
    width: 96%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}

.address-field-permanent {
    margin-top: 20px;
}

.address-field-permanent h5 {
    font-size: 18px;
    font-weight: 400;
    color: #101828;
}

.address-field-communication h5 {
    font-size: 18px;
    font-weight: 400;
    color: #101828;
    margin-top: 40px;
}

.address-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.address-checkbox span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #344054;
}

/* SelectInput  */

.css-14el2xx-placeholder {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #c1c1c1 !important;
}

/* UserProfile  */

.hr-line hr {
    margin-top: 30px !important;
    color: #b4b4b4;
}

/* UploadScholar  */

.select-batch {
    width: 70%;
    display: flex;
}

.select-user {
    margin-right: 20px;
}

.select-input-userprofile {
    width: 232px;
}

.select-input-userprofile-existing {
    width: 480px;
}

.select-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

/* RadioButton */

.select-radio {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.radio-button {
    cursor: pointer;
}

.radio-button-userprofile {
    display: flex;
    align-items: center;
}

.radio-button-userprofile span {
    font-size: 16px;
    font-weight: 400;
    color: #344054;
    padding-left: 10px;
}

/* DefaultPage */

.default-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.default-content h6 {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #101828;
}

.default-content span {
    font-weight: 400;
    font-size: 14px;
    color: #667085;
}

.default-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.default-button-invite {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.default-link-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* InviteSupervisorPage */

.invite-supervisor {
    display: flex;
    flex-direction: column;
}

.invite-supervisor-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.invite-supervisor-inputs {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
}

.supervisor-name {
    width: 100% !important;
    margin-right: 26px;
}

.patent-issue-input {
    width: 100% !important;
    margin-right: 26px;
}

.supervisor-switch-content {
    display: flex;
}

.supervisor-disciplines-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.supervisor-disciplines-heading {
    margin-top: 30px;
}

.supervisor-disciplines-heading h5 {
    font-weight: 400;
    font-size: 20px;
    color: #101828;
}

.supervisor-switch-text {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
}

.supervisor-switch-text span {
    font-weight: 400;
    font-size: 14px;
    color: #344054;
    padding-left: 8px;
}

.cb-buttons-users {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 30px;
}


/* AddScholarPage */

.scholar-input {
    width: 48%;
}

.scholar-input {
    width: 48%;
}

/* AssignSupervisorPage */

.aasign-supervisor-select {
    width: 320px !important;
    margin-right: 26px;
    margin-top: 20px;
}

.promote-supervisor-select {
    width: 320px !important;
    margin-right: 26px;
}

.promote-scholar-select {
    width: 320px !important;
    margin-right: 26px;
}

.cb-buttons-scholars {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.cb-buttons {
    display: flex;
    float: right;
    margin-top: 20px;
    height: 70px;
}

/* ScholarDefaultPage */

.profile-screen-default-page {
    width: 100%;
    margin-top: 60px;
}

.default-content-scholar {
    margin-bottom: -30px;
    width: 34%;
}

.default-content-scholar h6 {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    color: #101828;
}

.default-content-scholar span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    color: #667085;
}

/* AddDeliverablePage */

.deliverable-name {
    width: 82% !important;
    margin-right: 26px;
    margin-top: 18px;
}

.deliverable-input {
    width: 900px;
    align-items: center;
}

.cb-buttons-deliverable {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    margin-top: 20px;
}

.invite-deliverable-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* AllForms*/

.bottom-line {
    width: 1096px;
    height: 1px;
    background: #eaecf0;
    margin-top: 24px;
}

.bottom-line-scholar {
    width: 1070px;
    height: 1px;
    background: #eaecf0;
    margin: 30px 0px 0px 0px;
}

.bottom-line-personal-info {
    width: 750px;
    height: 1px;
    background: #eaecf0;
    margin-top: 24px;
}

/* Cards*/

.card {
    width: 40%;
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
}

.card-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.card-info-content {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    padding: 10px;
}

.bottom-line-card {
    width: 100px;
    height: 1px;
    background: #eaecf0;
}

.card-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
}

.card-action-edit a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-action-edit {
    list-style: none;
    font-size: 14px;
    text-decoration: none;
    color: #7f56d9;
    cursor: pointer;
}

.card-action-delete a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-action-delete {
    list-style: none;
    font-size: 14px;
    text-decoration: none;
    color: #F04438;
    cursor: pointer;
}

/* UserProfileModals */

.userprofile-textarea {
    width: 97%;
}

.userprofile-textarea-input {
    width: 100%;
    align-items: center;
    margin-top: 20px;
}

/* FeePolicy */

.assigned-fee-policy-amount {
    margin-top: 40px;
    margin-bottom: 40px;
}

.assigned-amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.amount-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}

.total-amount {
    display: flex;
    flex-direction: column;
}

.total-amount h4 {
    font-weight: 500;
    font-size: 14px;
    color: #101828;
}

.total-amount span {
    font-weight: 700;
    font-size: 20px;
    color: #7f56d9;
}

.total-paid h4 {
    font-weight: 500;
    font-size: 14px;
    color: #101828;
}

.total-paid span {
    font-weight: 700;
    font-size: 20px;
    color: #00b147;
}

.remaining-amount h4 {
    font-weight: 500;
    font-size: 14px;
    color: #101828;
}

.remaining-amount span {
    font-weight: 700;
    font-size: 20px;
    color: #ff0000;
}

.fee-policy-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collection-button {
    margin-right: 20px;
}

.bIiDOZ > img {
    padding-right: 0px !important;
}

.dWgjZc > img {
    padding-right: 8px !important;
}

.DrtLs > img {
    padding-right: 0px !important;
}

/* FeePolicy */
/* CollectionModal*/

.collectionModal.modal.show .modal-dialog {
    max-width: 400px;
}

.paybal-amount {
    margin-top: 20px;
}

.paybal-amount h6 {
    font-weight: 500;
    font-size: 14px;
    color: #667085;
}

.paybal-amount span {
    font-weight: 400;
    font-size: 16px;
    color: #5d43a5;
}

.fee-policy-modal {
    margin: 30px;
}

.fee-policy-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fee-mode-name {
    width: 100% !important;
    margin-top: 20px;
}

.payment-mode-name {
    width: 100% !important;
    margin-top: 20px;
}

.payment-label {
    font-weight: 500;
    font-size: 14px;
    color: #344054;
    margin-bottom: 8px;
}

.cb-buttons-fee-policy {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

/* FeePolicy */
/* AssignFeePolicy*/

.assignfeeModal.modal-dialog.modal-dialog-centered {
    max-width: 600px;
}

.assign-fee-policy-content {
    margin-top: 26px;
}

.assign-fee-policy-amount {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.select-supervisors-input {
    width: 100%;
    margin-top: 8px;
}

.select-supervisors-input-heading {
    font-size: 14px;
    font-weight: 400;
}

.milestone-fees {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 18px;
}

.milestone-fee-amount span {
    font-size: 16px;
    font-weight: 700;
}

/* .bottom-line-assign-fee-policy {
    width: 440px;
    height: 1px;
    background: #eaecf0;
} */

.all-milestones-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
}

.cb-buttons-assign-fee-policy {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

/* FeePolicy */
/* DiscountnModal*/

.discountModal.modal.show .modal-dialog {
    max-width: 1000px;
}

.fee-discount-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
}

.fee-discount-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 18px;
}

.fee-discount-headings {
    font-weight: 500;
    font-size: 14px;
    color: #101828;
}

.fixed-milestone-input {
    width: 13%;
}

.fixed-amount-input {
    width: 14%;
}

.discount-type-input {
    width: 16%;
}

.discount-input {
    width: 16%;
}

.after-discount-input {
    width: 12%;
}

/* Milestones- Inputs */

.invxln:hover {
    box-shadow: none !important;
}

/* Amounts/AfterDiscount- Inputs */

.eWviwc:hover {
    box-shadow: none !important;
}

.discount-footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.discount-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 23%;
    margin-top: 30px;
}

.after-discount-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 39%;
    margin-top: 30px;
}

.total-heading h4 {
    font-weight: 700;
    font-size: 20px;
    color: #101828;
}

.total-amount-heading h4 {
    font-weight: 700;
    font-size: 20px;
    color: #7f56d9;
}

.bottom-line-discount {
    width: 940px;
    height: 1px;
    background: #eaecf0;
    margin-top: 20px;
}

.cb-buttons-discount {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.invxln:hover {
    box-shadow: none !important;
}

.eWviwc:hover {
    box-shadow: none !important;
}

/* ErrorMessage-SelectInput */

.cbLTNH > [div] span {
    font-weight: 400 !important;
}

/* ErrorMessage-SimpleInput */

.dpYykE:hover {
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0 0 0px 2px #fff0f0 !important;
}

/* AddScholar-Address*/

.text-area-address {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
    width: 100%;
}

.profile-form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* .text-area-address-c { */
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
    width: 98%;
    float: left;
    /* } */

}

.correspondence-address {
    width: 48%;
    margin-right: 26px;
}

.profile-form-container > div {
    width: 100%;
    flex: 50%;
    padding-right: 20px;
    margin-bottom: 20px;
}

.permanent-address {
    width: 48%;
    margin-right: 26px;
}

.select-drop-down {
    margin-top: 8px;
}

/* AllFilters-SelectInput  */

.filter-select-drop-down {
    margin-top: 20px;
    width: 250px;
}

div {
    font-size: 14px;
}

/* Select-drop-down-error */

.selecterror {
    border: 1px solid #F04438;
    border-radius: 8px;
}

.select-error {
    border: none;
}

.selecterror:hover {
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0 0 0px 2px #fff0f0 !important;
}

.select-drop-down .selecterror .css-1s2u09g-control:hover {
    box-shadow: none;
}

.select-drop-down .selecterror .css-1pahdxg-control {
    border: none;
}

.select-drop-down .selecterror .css-1s2u09g-control {
    border: none !important;
}

.bomFbP > div span {
    font-weight: 400 !important;
}

/* Pagination */

.pagination-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px 20px 0px;
}

.pagination {
    display: flex;
    margin-bottom: 0px !important;
}

.pagination-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-item.active .page-link {
    z-index: 0;
    color: #ffffff;
    background-color: #f5f3fa;
    border-color: #f5f3fa;
}

.page-link {
    color: #1D2939 !important;
}

a.page-link {
    height: 40px;
}

.page-link:hover {
    background-color: #ffffff !important;
    z-index: 0 !important;
}

a.page-link {
    box-shadow: none !important;
    background-color: #ffffff !important;
    border: 1px solid #D0D5DD !important;
}

a.page-link:hover {
    background-color: none !important;
    border: 1px solid #D0D5DD !important;
}

.arrows-page span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1D2939;
    vertical-align: middle !important;
}

.arrows-page span1 {
    vertical-align: middle !important;
}

img.right-arrow {
    vertical-align: middle;
    padding-top: 2px;
}

img.left-arrow {
    vertical-align: middle;
    padding-top: 2px;
}

.select-items-pagination {
    border: 1px solid #D0D5DD;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 16px;
}

.select-items-pagination span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1D2939;
    padding-right: 16px;
    border-right: 1px solid #D0D5DD;
    height: 40px;
    display: flex;
    align-items: center;
}

.select-items-pagination select {
    border: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    padding-left: 16px;
    outline: none;
    color: #1D2939;
    font-size: 14px;
    font-weight: 400;
}

.disable span {
    color: #C8C8C8;
}

.disable span1 {
    color: #C8C8C8;
}

/* Pagination-ActiveClass */

li.page-item.active a {
    background-color: #F9FAFB !important;
}


/* ViewPage-WebsiteLink Color */

p.view-website-link {
    color: #7f56d9 !important;
    cursor: pointer;
}

p.view-website-link:hover {
    color: #5d43a5 !important;
}

p.view-website-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

img.website-link-icon {
    margin-left: 8px;
    align-items: center !important;
    width: 12px;
}

img.website-link-icon {
    vertical-align: middle !important;
}

.view-website-link a {
    text-decoration: none !important;
    color: #7f56d9;
}

.view-website-link a:hover {
    color: #5d43a5;

}

/* SelectInput-AddPage-Form */

.css-1n7v3ny-option {
    background-color: #f1ecfe !important;
}

.css-1pahdxg-control {
    box-shadow: 0 0 0 1px #f5f3fa !important;
}

.no-records-intable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* HeaderFilter*/

.header-filter {
    flex-flow: wrap;
}

/* ProgramTable-Actions*/

th.action-th {
    width: 208px !important;
}

/* DRCFcaulty-ListPage  */

.drc-select {
    width: 320px !important;
    margin-right: 26px;
    margin-top: 20px;
}

/* SelectInput-Error */

.css-1s2u09g-control:hover {
    box-shadow: none !important;
}

.control:hover {
    box-shadow: none !important;
}

.css-1pahdxg-control {
    border-color: 1px solid #D0D5DD !important;
    box-shadow: 0 0 0 1px #fff0f0 !important;
}

.css-1pahdxg-control:hover {
    box-shadow: 0 0 0 1px #fff0f0 !important;
}

/* SimpleInput-Error */

.ifTeuq:hover {
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 2px #fff0f0 !important;
}

/* BackButton-Add/EditPages */

.hAJsJC {
    justify-content: flex-start !important;
}

/* Calendar-WholeInputClickable*/

input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    cursor: pointer;
}

input[type='date']::before {
    content: '';
    color: #555;
    background-repeat: no-repeat;
    background-image: url('../images/calendar.svg');
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -1px;
    right: 0px;
}

input[type='date'] {
    position: relative;
}

::placeholder .sc-hKMtZM .fwJvfk {
    color: #00b147;
}

/* adjust increase/decrease button */
input[type='date']::-webkit-inner-spin-button {
    z-index: 1;
}

/* TextArea-HoverEffect */

.jsGcYj:hover {
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 2px #fff0f0 !important;
}

.beytA:hover {
    box-shadow: rgb(16 24 40 / 5%) 0px 1px 2px, 0px 0px 0px 2px #fff0f0 !important;
}

/* SimpleInput-HoverEffect */

.lknEoQ:hover {
    box-shadow: rgb(16 24 40 / 5%) 0px 1px 2px, 0px 0px 0px 2px #fff0f0 !important;
}

/* AddFeePolicy */
/* FixedPlaceholderInput */

.sc-iqcoie.cNxgGF {
    width: 50px;
}

.sc-iqcoie.Jkfkf {
    width: 50px;
}

.add-fee-policy-field {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
}

.add-fee-policy-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 20px;
}

.milestone-fixed-input {
    margin-right: 22px;
}

.fee-policy-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fee-policy-heading h4 {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    margin-bottom: 0px !important;
}

.fee-policy-heading span {
    font-size: 14px;
    font-weight: 300;
    color: #667085;
}

.enter-fee-amount {
    width: 35%;
}

.choose-milestone-amount {
    width: 70%;
}

.iaohUe:hover {
    box-shadow: none !important;
}

.cubMaX:hover {
    box-shadow: rgb(16 24 40 / 5%) 0px 1px 2px, rgb(244 235 255) 0px 0px 0px 2px;
}

.sc-iqcoie.dPvxPj {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 18px;
}

.sc-iqcoie.dxdLKp {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 18px;
}

/* AssignFeePolicyList */

.Paid {
    background-color: #ECFDF3;
    color: #027A48;
    width: 41px;
    height: 22px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    padding: 0px 24px 0px 24px;
}

.Nil {
    background-color: #f1edf7;
    color: #7f56d9;
    width: 41px;
    height: 22px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    padding: 0px 24px 0px 24px;
}

.Unpaid {
    background: rgba(225, 0, 0, 0.2);
    color: #E10000;
    width: 57px;
    height: 22px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    padding: 0px 24px 0px 24px;
}

/* DiscountModal */

.sc-fEOsli.ibEViC {
    font-size: 18px;
}

.sc-fEOsli.hSfNJc {
    font-size: 18px;
}

.sc-fnykZs.jGlVyF:hover {
    box-shadow: none !important;
}

/* ScholarAssignFeePolicy-View */

.scholar-assign-fee-view {
    color: #7f56d9 !important;
}

/* AssignDeliverableModal */

.assign-deliverable-modal {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 18px;
}

/* TextEditor-Description */

.editorClassName {
    min-height: 380px;
    width: 460px;
}

a.rdw-dropdown-selectedtext {
    color: #7f56d9 !important;
    text-decoration: none !important;
}

a.rdw-dropdown-selectedtext:hover {
    color: #5d43a5;
}

/* UploadDrag-Drop - AddScholar*/

.click-to-upload {
    display: flex;
}

.uploaded-images-list {
    align-items: flex-start;
    display: flex;
    flex-flow: wrap;
    float: left;
    justify-content: flex-start;
    width: 16%;
}

.uploaded-images-col {
    align-items: center;
    border-radius: 6px !important;
    display: flex;
    justify-content: flex-start;
    margin-right: 20px;
    position: relative;
}

.uploaded-images-col img {
    border-radius: 6px !important;
}

.uploaded-images-size {
    width: 80px;
    height: 80px;
}

.remove-uploaded-image {
    cursor: pointer;
    position: absolute;
    right: 6px;
    top: 6px;
}

.no-profile-image {
    margin-right: 20px;
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
}

.dummy-no-profile-image {
    width: 80px;
    height: 80px;
}

/* HomePageLoader */

.home-page-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    position: absolute;
    top: 50%;
    right: 46%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* SidebarFooterLogout */

.avtar-icon span {
    width: 46px;
    height: 46px;
    font-size: 16px;
    border-radius: 30px;
    padding: 10px;
    background-color: #7f56d9;
    border: 1px solid #7f56d9;
    color: #ffffff;
    cursor: default;
}

.avtar-icon {
    cursor: default;
}

/* Files-Upload */

.files-select {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.files-select a {
    color: #667085;
    text-decoration: none;
}

.files-select a:hover {
    color: #5b5e64;
}

.uploaded-file-remove {
    display: flex;
    border: 1px solid #eaecf0;;
    background-color: #f9fafb;
    padding: 4px 12px 4px 12px;
    border-radius: 6px;
    margin: 10px 10px 0px 0px;
}

.uploaded-file-remove-icon span {
    margin-left: 10px;
}

.remove-image-icon {
    width: 18px;
    cursor: pointer;
}

/* AssignDeliverableContent-ProgressBar */

.assignl-info-tab {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 6px;
}

.assign-info-tab span {
    color: #3f444b;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
}

.assign-info-subtab {
    padding-left: 32px;
}

.assign-info-subtab span {
    color: #667085;
}

.assign-info-subtab span:hover {
    color: #7f56d9;
}

.assign-info-tab span:hover {
    color: #7f56d9;
}

.assign-info-tab span:active {
    color: #7f56d9;
}

.progress-bar-assign {
    float: left;
    flex-direction: column;
    flex-flow: wrap;
    margin-top: 18px;
    width: 36%;
}

.progress-bar-content-assign {
    border-right: 1px solid #eaecf0;
}

.progress-bar-content-assign ul li {
    display: flex;
    align-items: flex-start;
    list-style: none;
    flex-direction: column;
}

.progress-bar-content-assign ul {
    margin-bottom: 20px;
    padding-left: 0px !important;
}

.progress-bar-content-assign ul li a {
    text-decoration: none;
}

.progress-bar-content-assign {
    margin-top: 30px;
}

.assign-deliverable-screen {
    float: left;
    position: relative;
    margin-top: 20px;
    padding: 0 50px 0 50px;
}

.deliverable-content-right {
    display: flex;
    margin-top: 30px;
}

.deliverables-content-right-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.deliverables-content-left {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.deliverable-subcontent-right {
    display: flex;
    flex-direction: column;
}

.deliverable-subcontent-right a {
    text-decoration: none;
    color: #7f56d9;
    margin-top: 4px;
}

.deliverable-subcontent-right a:hover {
    color: #5d43a5;
}

/* SelectDiscipline */

.css-xb97g8:hover {
    background-color: hsl(0, 0%, 90%) !important;
}

/* CollectionButton */

.DrtLs > img {
    padding-right: 12px !important;
}

/* ScrollbarColorChange */

::-webkit-scrollbar {
    width: 0.1rem;
}

::-webkit-scrollbar-track {
    background-color: #eaecf0;
}

/* RemovedBackButton-ViewPage */

.view-back-button {
    display: none !important;
}
























